<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Goal </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>

        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <div v-else>
          <div class="mt-3">
            <b-form-radio-group
              v-model="formInput.is_goal"
              @change="setGoal($event)"
            >
              <b-row>
                <b-col><b-form-radio :value="0">No Goal</b-form-radio></b-col>
                <b-col><b-form-radio :value="1">Goal</b-form-radio></b-col>
              </b-row>
            </b-form-radio-group>
          </div>
          <hr />
          <div v-if="formInput.is_goal == 1" class="mt-2">
            <b-row class="align-items-center">
              <b-col md="12"
                ><div class="main-label">
                  Action <span class="text-danger">*</span>
                </div></b-col
              >
              <b-col md="4"> A Customer Perform Event</b-col>

              <b-col md="3">
                <InputSelect
                  title=""
                  name="perform-event"
                  v-model="formInput.event_type_id"
                  v-bind:options="[{ id: 6, text: 'Purchase' }]"
                  valueField="id"
                  textField="text"
                  :v="$v.formInput.event_type_id"
                  :isValidate="$v.formInput.event_type_id.$error"
                >
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled>
                      --- Select Perform Event ---
                    </b-form-select-option>
                  </template>
                </InputSelect>
              </b-col>
              <b-col cols="12" class="px-3">
                <b-row class="header-filter mx-0 align-items-center row-gap-lg">
                  <b-col cols="12">
                    It Counts as a conversion when, the customer acheieves the
                    gaol, when perform event within:
                  </b-col>
                  <b-col cols="4" class="d-inline-flex align-items-center">
                    <InputText
                      textFloat=""
                      placeholder="0"
                      class="mb-0"
                      type="number"
                      v-model="formInput.value"
                      name="perform-days"
                      oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                      :isValidate="$v.formInput.value.$error"
                    />
                    <span class="mx-2">Days</span>
                  </b-col>
                  <b-col cols="5"> After message deliveried </b-col>
                  <b-col
                    class="text-danger"
                    cols="12"
                    v-if="$v.formInput.value.$error"
                  >
                    Please input value.
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              :disabled="isLoading"
              class="text-body rounded-pill btn-main"
              @click.prevent="submit"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    OtherLoading,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  validations: {
    formInput: {
      event_type_id: {
        required: requiredIf(function (item) {
          return item.is_goal === 1 && item.event_type_id == 0;
        }),
      },
      value: {
        required: requiredIf(function (item) {
          return item.is_goal === 1;
        }),
        minValue: minValue(0),
      },
    },
  },
  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      //   isSelected: this.form.is_goal,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
      type: 0,
      formInput: {
        id: 0,
        marketing_automation_id: 0,
        message_segmentation_id: Number(this.$route.params.id),
        event_type_id: 0,
        is_goal: 0,
        value: 0,
      },
    };
  },
  methods: {
    show() {
      this.formInput = { ...this.form.goal };

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submit() {
      // $emit('settingCondition', formInput)
      this.$v.$touch();
      if (this.$v.formInput.$error) return;

      this.$emit("submit", this.formInput);
      this.isShowSidebar = false;
    },
    setGoal(val) {
      if (val == 0)
        this.formInput = {
          id: this.formInput.id,
          marketing_automation_id: 0,
          message_segmentation_id: Number(this.$route.params.id),
          event_type_id: 0,
          is_goal: 0,
          value: 0,
        };
      else {
        this.formInput.value = 30;
        this.formInput.event_type_id = 6;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
</style>

<template>
  <div>
    <div class="bg-tabs-custom w-100">
      <b-row class="m-0">
        <b-col>
          <button
            @click.prevent="openSidebar"
            class="w-100 text-left btn-expand bg-tabs-custom"
          >
            <b-form-checkbox v-model="isGoalSelect">Goal</b-form-checkbox>
          </button>
        </b-col>
        <b-col class="text-right">
          <button
            class="btn-edit w-100 text-right"
            @click.prevent="openSidebar"
          >
            Edit
          </button>
        </b-col>
      </b-row>
    </div>
    <div class="p-3" v-if="isGoalSelect">
      <div v-if="form.goal.is_goal == 0">Goal : No Goal</div>
      <div v-else>Goal : Purchase within {{ form.goal.value }} Days</div>
    </div>
    <Sidebar
      :form="form"
      ref="sideBar"
      @submit="(val) => $emit('submitGoal', val)"
    />
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
export default {
  props: {
    form: {
      required: false,
    },
  },
  components: {
    Sidebar,
  },
  data() {
    return {
      isGoalSelect: this.$route.params.id != 0 ? true : false,
    };
  },
  methods: {
    openSidebar() {
      this.$refs.sideBar.show();
    },
  },
};
</script>

<style>
.marginbottom {
  margin-bottom: 0rem;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--theme-secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.ft-20 {
  font-size: 20px;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
::v-deep .header-tablepage {
  color: #092d53;
}
.btn-edit-general {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--primary-color);
  border-radius: 0px;
}
.btn-line-right {
  border-right: 1px solid var(--primary-color);
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
.add-new {
  text-decoration: underline;
}
.black-color {
  color: #000;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-edit-communication {
  color: gray;
  font-weight: 100;
}
.pd-bt-100 {
  padding-bottom: 100px;
}
.btn-expand {
  padding: 0;
  background-color: transparent;
  border: none;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
#collapseExample {
  border: 0;
}
</style>
